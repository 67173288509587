<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技&CSDN，招聘直播宣讲会圆满落幕
              </p>
              <div class="fst-italic mb-2">2022年2月11日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >招聘</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >直播宣讲</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/05/image03.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p class="mb-4">
                    曾幻想仗剑走天涯，也曾希冀在一方天地大展身手，怀揣的梦想是否都已实现？
                  </p>
                  <p class="mb-4">
                    2月11日，非凸科技与CSDN高校俱乐部联合举办了一场招聘直播宣讲会，“非凸科技-带你走进数智交易”。非凸科技北京分公司负责人朱为老师，深入浅出，将数智交易行业与Rust语言的结合完美诠释了。
                  </p>
                  <h4>一、合作伙伴</h4>
                  <p class="my-4">
                    非凸科技荣获CSDN颁发的“人才合作伙伴”证书，成为CSDN线上工程师岗位招聘年度合作伙伴。双方一致认为，在新的机遇和挑战面前，双方将协同共进，共商共赢，充分运用各自资源优势，在工程师人才招聘方面探索合作新模式，拓宽合作新渠道。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/05/image01.jpg"
                      alt="人才合作伙伴"
                    />
                  </figure>
                  <h4>二、新布局</h4>
                  <p class="mb-4">
                    数智交易在中国的蓬勃发展，人工智能在投资领域的应用，以及交易工具的丰富，使得程序化交易在证券市场越来越受欢迎。与此同时，国内数智交易领域已开始布局Rust方向的人才招聘，Rust在该领域的应用或许成为一种新趋势。
                  </p>
                  <p class="mb-4">
                    非凸科技是国内领先的智能算法和交易系统服务商，专注于数智交易领域的研究和开发。公司正基于Rust 生态，打造业内领先的智能交易系统，为中小投资者保驾护航。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/05/image02.jpg"
                      alt="直播现场"
                    />
                  </figure>
                  <h4>三、直播现场</h4>
                  <p class="my-4">
                    据后台记录数据显示，1.5小时的直播，累计观看人数达5000+，直播间热度破万！期间还获得了激烈的点赞和讨论。
                  </p>
                  <strong>来自粉丝的认可:</strong>
                  <ul class="list-unstyled my-4">
                    <li class="mb-2">
                      <i class="bi bi-hand-thumbs-up text-primary"></i>
                      对数智交易感兴趣，听了很受益
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-hand-thumbs-up text-primary"></i>
                      准备从互联网圈跳到数智交易圈
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-hand-thumbs-up text-primary"></i>
                      厉害了，硬件资源实力强！
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-hand-thumbs-up text-primary"></i>
                      非凸科技yyds！
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-hand-thumbs-up text-primary"></i>
                      朱老师讲得专业！
                    </li>
                  </ul>

                  <strong>来自粉丝的疑问:</strong>
                  <ul class="list-unstyled my-4">
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      交易圈是不是比互联网前景好些？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      非凸企业文化是什么样的？工程师驱动吗？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      模型算法常用的有哪些？会不会出现相同模型之间的竞争交易？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      柜台如果没有 Rust 接口，只能用FFI来调C++吗？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      一些 Rust 生态中没有的数学库，非凸是自研的吗？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      如何考核绩效？数智交易领域的薪资是不是bonus占比较大，与业绩强挂钩？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      校招生，能给到30k+吗？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      非凸的实习形式是什么样的？有时间要求吗？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      如何看待社招做搜广推的同学转行过来？工作内容match吗？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-patch-question-fill text-primary"></i>
                      会 Rust，但没有相关经验，社招可以吗？
                    </li>
                  </ul>

                  <p>
                    <strong>
                      本次招聘直播宣讲会圆满落幕，是不是还没听过瘾？下次直播即将开启，不仅给大家答疑解惑，还有【技术干货】分享，值得期待～
                    </strong>
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News01",
};
</script>

<style></style>
